import React, { useRef, useState } from "react"
import { navigate } from "gatsby"
// Import Components
import Divider from "@/components/atoms/Divider"
import MenuItemDropdown from "@/components/organisms/MenuItemDropdown"
// Import Styles
import "@/styles/accordion.css"

const AccordionItem = ({
    handleToggle,
    active,
    item,
    index,
    setMobileMenuOpen,
}) => {
    const contentEl = useRef()
    const { title, href, children } = item
    // const accordionIcon = active === index ? minusIcon : plusIcon
    const accordionIcon = active === index ? " active" : ""

    const handleSingleLink = () => {
        navigate(href)
        setMobileMenuOpen(false)
    }
    return (
        <div className="mb-2 border-1 border-solid border-black">
            <div className="">
                <div
                    className={`accordion-toggle flex items-start cursor-pointer justify-between bg-white ${active === index ? "active text-msqred" : "pb-[9px] bg-white"}`}
                    onClick={() =>
                        children ? handleToggle(index) : handleSingleLink()
                    }
                >
                    <p className="accordion-title pb-[18px]">{title}</p>
                    {children && (
                        <div
                            className={`accordion-icon relative w-[20px] h-[20px] cursor-pointer${accordionIcon}`}
                        ></div>
                    )}
                </div>
                {active !== index && <Divider variant="menu" height="1" />}
            </div>

            <div
                ref={contentEl}
                className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${active === index ? "max-h-[100vh]" : "max-h-0"}`}
            >
                <div className="flex-auto min-h-0">
                    {children && (
                        <MenuItemDropdown
                            children={children}
                            setMobileMenuOpen={setMobileMenuOpen}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

const Accordion = ({ data, setMobileMenuOpen }) => {
    const [active, setActive] = useState(null)

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null)
        } else {
            setActive(index)
        }
    }

    return (
        <>
            {data.map((item, index) => (
                <AccordionItem
                    key={index}
                    item={item}
                    active={active}
                    handleToggle={handleToggle}
                    index={index}
                    setMobileMenuOpen={setMobileMenuOpen}
                />
            ))}
        </>
    )
}

export default Accordion
