const sendToTagManager = ( eventObject = {} ) => {
    let eventData = {
        'event':                'event.' + eventObject.type,
        'gtm.action':           eventObject?.action || undefined,
        'gtm.category':         eventObject?.category || undefined,
        'gtm.target':           eventObject?.target || undefined,
        'gtm.label':            eventObject?.label || undefined,
        'gtm.platform':         eventObject.platform || undefined,
        // linky based stuff
        'gtm.elementClasses':   eventObject.target?.className || undefined,
        'gtm.elementId':        eventObject.target?.id || undefined,
        'gtm.elementUrl':       eventObject.target?.href || eventObject.target?.action || undefined,
    }

    console.log( 'incoming analytics event', eventObject );
    if( typeof window.dataLayer !== 'undefined' ) {
        window.dataLayer.push( eventData );
        const event = new CustomEvent('vsb-analytics-event', {
            detail: eventData
        });
        document.dispatchEvent(event)
    }
}


export default sendToTagManager
