import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link as ThemedLink } from "theme-ui"

import sendToTagManager from "@/utils/analyticsHelper"
const isBrowser = typeof window !== "undefined"

const Link = ({ children, href = null, to = null, variant = "styles.a.default", onClick = null, context = null, action = null, ...props }) => {

    // allow either href or to prop to work
    const destination = href ?? to;
    // double check any destinations for internal links
    const internal = /^\/(?!\/)/.test(destination)
    const isFile = /\.[0-9a-z]+$/i.test(destination)

    const useGatsbyLink = internal && !isFile;
    //console.log( 'testing link: ', { destination, useGatsbyLink })

    const analyticsDispatcher = (e) => {
        if (context && isBrowser) {
            sendToTagManager({
                action: action ?? 'navigation',
                label: context,
                category: 'Links & Buttons',
                target: to ?? href,

            })
        }
        // handle any additional onclick attributes pushed to component
        if (onClick && typeof onClick === 'function') onClick();
    }
    return (
        (useGatsbyLink ? (
            <GatsbyLink
                {...props}
                sx={{
                    variant: variant,
                }}
                to={destination}
                onClick={() => analyticsDispatcher()}
            >
                {children}
            </GatsbyLink>
        ) : (
            <a
                {...props} href={destination} onClick={() => analyticsDispatcher()}
                sx={{
                    variant: variant,
                }}
            >
                {children}
            </a>
        ))
    )
}

export default Link;
