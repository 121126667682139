import React from "react"
import { Link } from "gatsby"
// Import Components
import Button from "@/components/atoms/Button"
import Accordion from "@/components/molecules/Accordion"
// Import Data
import { megaMenuData } from "@/content/megaMenuData"

const MobileMenu = ({ setMobileMenuOpen }) => {
    return (
        <div className="mobile-menu fixed overflow-scroll h-full w-full bg-white z-[99] px-8 py-10 block lg:hidden">
            <Accordion
                data={megaMenuData}
                setMobileMenuOpen={setMobileMenuOpen}
            />
            <div className="flex flex-col gap-[20px]">
                <Link href="https://join.modsquad.com/join-the-mods" className="">
                    <Button variant="secondary" className="w-full">
                        Join the Mods
                    </Button>
                </Link>

                <Link to="/contact">
                    <Button variant="primary" className="w-full">
                        Contact us
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default MobileMenu
