import React, { useEffect, useState } from "react"
import Link from "@/components/atoms/Link";
import Button from "@/components/atoms/Button"
import Divider from "@/components/atoms/Divider"

// Smaller Components
const Card = ({
    card,
    setMobileMenuOpen,
    handleDropdownMenuClick,
    dropdownMenuClicked,
}) => {
    const { image, title, content, href, externalLink, linkText } = card
    return (
        <div
            className="card relative p-4 rounded-[8px] bg-porsche w-full fade-in-item"
            style={{
                animationDelay: "100ms",
            }}
        >
            {image && (
                <img
                    src={image}
                    // srcSet={`${image} 575w, ${tabletImage} 992w, ${image} 1024w"`}
                    // sizes={`
                    // (min-width: 375px) 100vw, 575px, (min-width: 768px) 100vw, 992px, (min-width: 1024px) 100vw, 1024px`}
                    alt=""
                    // height={140}
                    className="h-[140px] object-cover w-full"
                />
            )}
            <div className="content">
                <h6 className="text-lg my-2">
                    {href && externalLink ? (
                        <Link href={href}
                            onClick={
                                setMobileMenuOpen
                                    ? () => {
                                          setMobileMenuOpen(false)
                                      }
                                    : handleDropdownMenuClick
                            }
                            className={`stretched-link${dropdownMenuClicked ? " dropdown-active" : ""}`}
                            variant="styles.a.card"
                        >
                            {title}
                        </Link>
                    ) : (
                        <Link
                        to={href}
                        context="header"
                        onClick={
                            setMobileMenuOpen
                                ? () => {
                                      setMobileMenuOpen(false)
                                  }
                                : handleDropdownMenuClick
                        }
                        className={`stretched-link${dropdownMenuClicked ? " dropdown-active" : ""}`}
                         variant="styles.a.card"
                    >
                        {title}
                    </Link>
                    )}
                </h6>

                {content && <p className="small">{content}</p>}

                {href && linkText && (
                    <Button variant="text" icon={true} className="mt-6">
                        {linkText}
                    </Button>
                )}
            </div>
        </div>
    )
}

const SubMenuItem = ({
    item,
    setMobileMenuOpen,
    index,
    handleDropdownMenuClick,
    dropdownMenuClicked,
}) => {
    const { title, nestedMenu } = item

    // TODO:  Break this out to a component
    const LinkContent = ({ href, title, externalLink }) => {
        return (
            href &&
            (externalLink ? (
                <Link href={href}
                    className="font-inter text-[16px] w-full text-msqblack flex justify-between hover:text-msqred hover:font-bold  border-none transition-all duration-400 ease-in-quart has-icon icon-arrow"
                    variant=""
                    // className="font-proxima font-bold w-full text-msqblack flex justify-between hover:text-msqred border-none transition-all duration-400 ease-in-quart"
                    // small
                >
                    {title}
                </Link>
            ) : (
                <Link
                    context="header"
                    to={href}
                    className={`font-inter text-[16px] w-full text-msqblack flex justify-between hover:text-msqred hover:font-bold border-none transition-all duration-400 ease-in-quart has-icon icon-arrow ${dropdownMenuClicked ? " dropdown-active" : ""}`}
                    // className={`font-proxima font-bold w-full text-msqblack flex justify-between hover:text-msqred border-none transition-all duration-400 ease-in-quart ${dropdownMenuClicked ? " dropdown-active" : ""}`}
                    // small
                    variant=""
                    onClick={handleDropdownMenuClick}
                >
                    {title}
                </Link>
            ))
        )
    }

    return (
        <div
            className="sub-menu flex flex-col fade-in-item"
            style={{
                animationDelay: "calc(" + index + " * 100ms)",
            }}
        >
            {title && <h6 className="text-[18px] font-bold">{title}</h6>}
            {title && <Divider variant="menu" />}
            {nestedMenu &&
                nestedMenu.length > 0 &&
                nestedMenu.map((item, index) => (
                    <div
                        className="nested-menu fade-in-item mb-4"
                        style={{
                            animationDelay: "calc(" + index + " * 100ms)",
                        }}
                        key={index}
                        onClick={() =>
                            setMobileMenuOpen && setMobileMenuOpen(false)
                        }
                    >
                        <LinkContent
                            key={index}
                            href={item.href}
                            title={item.title}
                            externalLink={item.externalLink}
                        />
                        {item.content && (
                            <p className="small font-inter text-[#8D8F94] opacity-60 leading-tight">
                                {item.content}
                            </p>
                        )}
                        {/* <Divider variant="menu" height={1} /> */}
                    </div>
                ))}
        </div>
    )
}

// Render Component
const MenuItemDropdown = ({ children, setMobileMenuOpen }) => {
    const { ftCard, submenu, card, additionalClass } = children
    const extraClass = additionalClass ? ` ${additionalClass}` : ""

    // Remove active link state so that the menu can be hoverable again - fun gatsby-ness
    let [dropdownMenuClicked, setDropdownMenuClicked] = useState(false)
    const handleDropdownMenuClick = () => {
        setDropdownMenuClicked(!dropdownMenuClicked)
    }
    useEffect(() => {
        if (dropdownMenuClicked)
            setTimeout(() => setDropdownMenuClicked(false), 1000)
    }, [dropdownMenuClicked])

    return (
        <div
            className={`mega-menu-dropdown lg:absolute lg:top-[98px] m-0 lg:ml-[-23px] flex-col lg:flex-row bg-white px-0 pb-[24px] lg:px-[36px] lg:border-b-[6px] lg:border-solid lg:border-msqred z-10 flex justify-start items-start gap-[15px] lg:gap-[48px] lg:shadow-dropdownMenu${extraClass}`}
        >
            {/* Submenu Items */}
            {submenu?.leftCol && (
                <div
                    className={`gap-[15px] lg:gap-8 lg:gap-y-[30px] w-full lg:w-64 flex flex-col`}
                >
                    {submenu.leftCol.map((item, index) => (
                        <SubMenuItem
                            item={item}
                            index={index}
                            key={index}
                            setMobileMenuOpen={setMobileMenuOpen}
                            handleDropdownMenuClick={handleDropdownMenuClick}
                            dropdownMenuClicked={dropdownMenuClicked}
                        />
                    ))}
                </div>
            )}

            {submenu?.rightCol && (
                <div className={`gap-8 gap-y-[30px] w-full lg:w-64`}>
                    {submenu.rightCol.map((item, index) => (
                        <SubMenuItem
                            item={item}
                            index={index}
                            key={index}
                            setMobileMenuOpen={setMobileMenuOpen}
                            handleDropdownMenuClick={handleDropdownMenuClick}
                            dropdownMenuClicked={dropdownMenuClicked}
                        />
                    ))}
                </div>
            )}

            {/* Featured Card */}
            {ftCard && card?.title && (
                <div className="w-full lg:w-64">
                    <Card
                        card={card}
                        setMobileMenuOpen={setMobileMenuOpen}
                        handleDropdownMenuClick={handleDropdownMenuClick}
                        dropdownMenuClicked={dropdownMenuClicked}
                    />
                </div>
            )}
        </div>
    )
}

export default MenuItemDropdown
