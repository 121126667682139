import React from "react"
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
// Components
import ButtonLink from "@/components/atoms/ButtonLink"
// Styles
import "@/styles/extralife.css"

const ExtraLayout = ({ children }) => {
    const teamPageUrl =
        "https://www.extra-life.org/index.cfm?fuseaction=donorDrive.team&teamID=63861"
    return (
        <div
            id="el-container"
            sx={{ color: "white", position: "relative", overflow: "hidden" }}
        >
            <Link to="/">
                <StaticImage
                    src="../images/extralife/msq-logo.png"
                    alt="ModSquad"
                    layout="constrained"
                    placeholder="blurred"
                    sx={{
                        position: "absolute",
                        top: 20,
                        left: 20,
                        zIndex: 999,
                        width: [100, 100, 160],
                    }}
                />
            </Link>

            <div
                sx={{
                    position: "absolute",
                    top: [1, 1, 20],
                    right: [2, 2, 20],
                    zIndex: 999,
                    display: ["none", "inline-block", "inline-block"],
                }}
            >
                <ButtonLink
                    bg="hotRed"
                    color="white"
                    to={teamPageUrl}
                    variant="solid"
                >
                    Make a Donation
                </ButtonLink>
            </div>

            {children}

            <p
                sx={{
                    fontSize: 14,
                    color: "white",
                    pl: 4,
                    pb: 4,
                    mt: 200,
                    lineHeight: "19px",
                }}
            >
                <Link
                    //  variant='styles.a.thinWhite'
                    sx={{
                        textTransform: "uppercase",
                        color: "white",
                        textDecoration: "underline",
                    }}
                    to="/privacy-and-cookies"
                >
                    Privacy and Cookies
                </Link>
                <br />©{new Date().getFullYear()} ModSquad, Inc
            </p>
            <div
                sx={{
                    position: "absolute",
                    bottom: 2,
                    right: 2,
                    maxWidth: 160,
            }}>
            <StaticImage
                src="../images/extralife/10yrlogo.png"
                alt="ModSquad 10 Year Extra Life Anniversary Logo"
                layout="constrained"
                placeholder="blurred"
            />
            </div>
        </div>
    )
}

export default ExtraLayout
