import React from "react"
import Link from "@/components/atoms/Link"
import { jsx } from "theme-ui"
// Components
import FooterNav from "@/components/organisms/footer-nav"
import CookieNotice from "@/components/molecules/CookieNotice"
// Images
import Vespa from "@/images/footer-vespa.png"
import roundel from "@/images/roundel.png"
import instagram from "@/images/social/instagram.svg"
import youtube from "@/images/social/youtube.svg"
import linkedin from "@/images/social/linkedin.svg"
import facebook from "@/images/social/facebook.svg"
import twitter from "@/images/social/twitter.svg"

// const SocialLink = (props) => (
//     <div>
//         <Link//             sx={{
//                 variant: "styles.a.mobilemenu",
//                 fontSize: 0,
//                 fontFamily: "sans",
//                 textTransform: "uppercase",
//                 fontweight: 100,
//             }}
//             href={props.href}
//             target="_blank"
//             rel="noreferrer"
//         >
//             {props.children}
//         </Link>
//     </div>
// )

const SocialIcon = (props) => (
    <div>
        <img
            src={props.src}
            alt={props.alt}
            sx={{
                width: 30,
                bg: "white",
                "&:hover": {
                    bg: "contiYellow",
                },
            }}
        />
    </div>
)

const Footer = () => {
    return (
        <footer
            sx={{
                mx: "auto",
                backgroundColor: "#111",
                color: "#fff",
                padding: 4,

                flexShrink: "0",
                position: "relative",
                // height: 300,
            }}
        >
            <FooterNav />

            <div
                sx={{
                    maxWidth: [280, "col6"],
                    mx: "auto",
                    mb: [120, 120, 0],
                    display: "flex",
                    flexDirection: ["row"],
                    flexWrap: "wrap",
                    gap: [3],
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Link
                    href="https://www.facebook.com/ModSquadInc/"
                    target="_blank"
                    variant="styles.a.socialLink"
                    context="footer-lower"
                >
                    <SocialIcon src={facebook} alt="Facebook" />
                </Link>
                <Link
                    href="https://twitter.com/modsquad"
                    target="_blank"
                    context="footer-lower"
                    variant="styles.a.socialLink"
                >
                    <SocialIcon src={twitter} alt="Twitter" />
                </Link>
                <Link
                    href="https://instagram.com/modsquad"
                    target="_blank"
                    context="footer-lower"
                    variant="styles.a.socialLink"
                >
                    <SocialIcon src={instagram} alt="Instagram" />
                </Link>
                <Link
                    href="https://www.linkedin.com/company/modsquad-inc-"
                    target="_blank"
                    context="footer-lower"
                    variant="styles.a.socialLink"
                >
                    <SocialIcon src={linkedin} alt="Linkedin" />
                </Link>
                <Link
                    href="https://www.youtube.com/user/MetaverseModSquad"
                    target="_blank"
                    context="footer-lower"
                    variant="styles.a.socialLink"
                >
                    <SocialIcon src={youtube} alt="YouTube" />
                </Link>
            </div>

            <div
                sx={{
                    position: ["static", "absolute"],
                    mx: ["auto", 0],
                    textAlign: ["center", "left"],
                    bottom: 32,
                    left: 32,
                }}
            >
                <div
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <img src={roundel} alt="ModSquad Roundel" width="40" />
                    <p
                        sx={{
                            fontSize: 12,
                            fontFamily: "sans",
                            fontWeight: "100",
                            color: 'white',
                        }}
                    >

                            <Link
                                to="/privacy-and-cookies"
                                context="footer-lower"
                                variant="styles.a.thinWhite"
                                sx={{ textTransform: "uppercase" }}
                            >
                                Privacy and Cookies
                            </Link>
{/*                         <Link
                            variant="styles.a.thinWhite"
                            sx={{ textTransform: "uppercase" }}
                            to="/privacy-and-cookies"
                        >
                            Privacy and Cookies
                        </Link> */}
                        <br />©{new Date().getFullYear()} ModSquad, Inc
                    </p>
                </div>
            </div>

            <img
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    height: [140, 200],
                }}
                src={Vespa}
                alt="Scooter"
            />

            <CookieNotice />
        </footer>
    )
}

export default Footer
