import resourceCardImg from "@/images/resources/spotify-hero.png"
import ctaMoped from "@/images/home/cubeless.webp"
// import desktopFtCardImage from "@/images/menu/menu-featured-image-238x140.jpg"
// import tabletFtCardImage from "@/images/menu/menu-featured-image-650x160.jpg"

export const megaMenuData = [
    {
        title: "Services",
        href: "/cx-services",
        children: {
            ftCard: false,
            submenu: {
                leftCol: [
                    {
                        title: "Customer Support",
                        nestedMenu: [
                            {
                                title: "General Support",
                                href: "/cx-services/customer-support",
                                content:
                                    "Multi-language tier-1 to advanced support.",
                            },
                            {
                                title: "Technical Support",
                                href: "/cx-services/technical-support",
                                content:
                                    "Skilled troubleshooting at any tier.",
                            },
                            {
                                title: "CRM & Tool Integration",
                                href: "/cx-services/consulting-services",
                                content:
                                    "Get the most from your AI tools.",
                            },
                        ],
                    },
                    {
                        title: "Data, Security, & Compliance",
                        nestedMenu: [
                            {
                                title: "Our Secure CX Platform",
                                href: "/security",
                                content:
                                    "Our patent-pending, remote work security solution.",
                            },
                        ]
                    },
                ],
                rightCol: [
                    {
                        title: "Moderation",
                        nestedMenu: [
                            {
                                title: "Trust & Safety",
                                href: "/cx-services/trust-and-safety",
                            },
                            {
                                title: "Content Moderation",
                                href: "/cx-services/content-moderation",
                            },
                            {
                                title: "Community Management",
                                href: "/cx-services/community-management",
                            },
                            {
                                title: "Social Media",
                                href: "/cx-services/social-media",
                            },
                        ],
                    },

                ],
            },
        },
    },
    {
        title: "AI & Automation",
        href: "/ai-and-automation",
    },
    {
        title: "Industries",
        href: "/our-work",
        children: {
            ftCard: false,
            submenu: {
                leftCol: [
                    {
                        title: "Areas of Specialization",
                        nestedMenu: [
                            {
                                title: "Gaming / Player Experience",
                                href: "/industries/gaming",
                            },
                            {
                                title: "Travel & Tourism",
                                href: "/industries/travel"
                            },
                            {
                                title: "E-Commerce & Retail",
                                href: "/industries/e-commerce-and-retail"
                            },
                            {
                                title: "Technology",
                                href: "/industries/technology"
                            },
                            {
                                title: "Crypto & NFTs",
                                href: "/industries/crypto-and-nfts"
                            },
                            {
                                title: "Metaverse",
                                href: "/industries/metaverse",
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        title: "Why ModSquad?",
        href: "/why-modsquad",
        // children: {
        //     ftCard: true,
        //     submenu: {
        //         leftCol: [
        //             {
        //                 title: "The ModSquad Difference",
        //                 nestedMenu: [
        //                     {
        //                         title: "Our Manifesto",
        //                         href: "/why-modsquad",
        //                         content: "The intelligent alternative to outdated outsourcing."
        //                     },
        //                     {
        //                         title: "Low Cost, High Quality",
        //                         href: "/spend-less-deliver-more",
        //                     },
        //                     {
        //                         title: "Remote Experts",
        //                         href: "/remote",
        //                     },
        //                     {
        //                         title: "Mods Vs Agents",
        //                         href: "/mods",
        //                     },
        //                     // {
        //                     //     title: "Outsourcing Belongs in the Outhouse",
        //                     //     href: "/outsourcing-belongs-in-the-outhouse",
        //                     // }
        //                 ],
        //             },
        //         ],
        //     },
        // },
    },
    {
        title: "Blog & Resources",
        href: "/the-blog",
        children: {
            ftCard: true,
            additionalClass: "menu-left-indent",
            submenu: {
                leftCol: [
                    {
                        title: "Blog",
                        nestedMenu: [
                            {
                                title: "The Latest",
                                href: "/the-blog",
                                content: "What we're thinking about today."
                            },
                            {
                                title: "The Archives",
                                externalLink: true,
                                href: "https://archives.modsquad.com",
                                content: "2008-2023."
                            },
                        ],
                    },
                    {
                        title: "Resources",
                        nestedMenu: [
                            {
                                title: "Resource Library",
                                href: "/resources",
                                content: "Case studies, ebooks, guides, and whatnot."
                            },
                        ],
                    },
                ],
            },
            card: {
                // image: desktopFtCardImage,
                // tabletImage: tabletFtCardImage,
                image: resourceCardImg,
                title: "Spotify Case Study",
                content: "Spotify Doesn’t Miss a Beat with Help from ModSquad",
                href: "/resources/spotify-case-study",
                linkText: "Learn more",
            },
        },
    },
    {
        title: "Company",
        href: "/about",
        children: {
            ftCard: true,
            additionalClass: "menu-left-indent",
            submenu: {
                leftCol: [
                    {
                        title: "Get to Know Us",
                        nestedMenu: [
                            {
                                title: "Our Work",
                                href: "/our-work",
                            },
                            {
                                title: "About",
                                href: "/about",
                            },
                            {
                                title: "Press",
                                href: "/press-center",
                            },
                            {
                                title: "Careers",
                                externalLink: true,
                                href: "https://join.modsquad.com/careers",
                            },
                            // {
                            //     title: "Extra Life",
                            //     href: "/extralife"

                            // },
                        ],
                    },
                ],
            },
            card: {
                // image: cubelessCardImg,
                image: ctaMoped,
                title: "Cubeless",
                content: "Our patent-pending, remote-native security solution.",
                href: "https://cubeless.io",
                externalLink: true,
                linkText: "Learn more",
            },
        },
    },
]
