import React from "react"

// Replaces ThicLine & BlogHr & default <hr>
const Divider = ({ variant = "default", className, height }) => {
    const heightVariants = {
        1: "border",
        2: "border-t-[2px]",
        8: "border-t-[8px]",
    }

    const findClasses = className ? ` ${className}` : ""
    let dividerStyles = ""
    switch (variant) {
        case "thin":
            dividerStyles = `${height ? heightVariants[height] : "border"} w-[280px] md:w-[500px] my-[8px] mx-auto`
            break
        case "thick":
            dividerStyles = `${height ? heightVariants[height] : "border-t-[16px]"} my-0 border-t-current`
            break
        case "menu":
            dividerStyles = `${height ? heightVariants[height] : "border-t-[1px]"} mb-[14px] w-full border-t-current`
            break
        default:
            dividerStyles = `${height ? heightVariants[height] : "border"} border-t-current`
            break
    }
    const wrapperClasses =
        variant === "menu" || variant === "submenu" ? "" : "mx-auto"
    return (
        <div className={wrapperClasses}>
            <hr className={dividerStyles.concat(findClasses)} />
        </div>
    )
}

export default Divider
